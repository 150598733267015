import React, { useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';

const SOCKET_SERVER_URL = 'https://vaffixdevcodeapi.wearedev.team/';

const ScreenShare = () => {
  const [isStreaming, setIsStreaming] = useState(false);
  const [platform, setPlatform] = useState('facebook'); // Default to Facebook
  const mediaStreamRef = useRef(null);
  const socketRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  useEffect(() => {
    const handleReconnect = () => {
      console.log('Reconnecting to server...');
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      socketRef.current = io(SOCKET_SERVER_URL, {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 20000,
      });

      socketRef.current.on('connect', () => {
        console.log('Connected to server');
      });

      socketRef.current.on('connect_error', (err) => {
        console.error('Socket connection error:', err);
      });

      socketRef.current.on('disconnect', (reason) => {
        console.log(`Socket disconnected. Reason: ${reason}`);
        setIsStreaming(false);
      });
    };

    handleReconnect();

    return () => {
      stopStreaming();
    };
  }, []);

  const startStreaming = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: { 
          width: { ideal: 1280 }, 
          height: { ideal: 720 }, 
          frameRate: { ideal: 30 } 
        },
      });
  
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
  
      const combinedStream = new MediaStream([
        ...screenStream.getTracks(),
        ...audioStream.getTracks(),
      ]);
  
      mediaStreamRef.current = combinedStream;
  
      const mediaRecorder = new MediaRecorder(combinedStream, {
        mimeType: 'video/webm;codecs=vp9,opus',
        videoBitsPerSecond: 2500000, // Set maximum video bitrate
      });
  
      mediaRecorderRef.current = mediaRecorder;
  
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          console.log('Sending media data, size:', event.data.size);
          event.data.arrayBuffer().then((buffer) => {
            socketRef.current.emit('media-data', buffer);
          });
        }
      };
  
      mediaRecorder.start(1000); // Send data every second
  
      socketRef.current.emit('start-stream', 'user1', platform);
  
      setIsStreaming(true);
    } catch (err) {
      console.error("Error capturing screen and audio:", err);
    }
  };  

  const stopStreaming = () => {
    if (socketRef.current) {
      socketRef.current.emit('stop-stream');
      socketRef.current.disconnect();
      socketRef.current = null;
    }
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    setIsStreaming(false);
  };

  return (
    <div>
      <select
        value={platform}
        onChange={(e) => setPlatform(e.target.value)}
      >
        <option value="youtube">YouTube</option>
        <option value="facebook">Facebook</option>
      </select>
      <button onClick={isStreaming ? stopStreaming : startStreaming}>
        {isStreaming ? 'Stop Streaming' : 'Start Streaming'}
      </button>
    </div>
  );
};

export default ScreenShare;